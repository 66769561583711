import React from 'react';
import {useNavigate} from 'react-router-dom';
import useAuthenticator from './hooks/useAuthenticator';

interface IAuthGuard {
  redirect?: boolean;
}
const AuthGuard: React.FC<IAuthGuard> = ({children}) => {
  const {recertification} = useAuthenticator();

  const navigate = useNavigate();
  const [interactionReady, setInteractionReady] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        await recertification();
        setInteractionReady(true);
      } catch (e) {
        const redirectUri = encodeURIComponent(
          `${window.location.pathname}${window.location.search}`,
        );
        navigate(`/signin?redirect_uri=${redirectUri}`, {
          replace: true,
        });
      }
    })();
  }, []);

  if (interactionReady) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return null;
  }
};

export default AuthGuard;
