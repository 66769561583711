import React from 'react';
import {FallbackProps} from 'react-error-boundary';

import useDialog from 'hooks/useDialog';

const ERROR_SERVER_MAINTENANCE = 600;

const ErrorBoundaryFallback: React.FC<FallbackProps> = ({error, resetErrorBoundary}) => {
  const dialog = useDialog();

  React.useEffect(() => {
    if (ERROR_SERVER_MAINTENANCE === (error as any).status) {
      const imageUrl: string | undefined = (error as any)?.data?.data?.imageUrl;
      if (imageUrl !== undefined) {
        dialog.show({
          confirm: '',
          type: 'maintenance',
          content: imageUrl,
          onConfirm: () => {},
        });
      } else {
        dialog.show({
          type: 'warning',
          title: (error as any)?.data?.statusMsg || '서버 점검 중입니다.',
          onConfirm: () => {},
          icon: 'far fa-exclamation-circle',
        });
      }
    }

    return () => {
      resetErrorBoundary();
    };
  }, [error]);

  return null;
};

export default ErrorBoundaryFallback;
