import React from 'react';
import {SvgIconProps} from '@mui/material/SvgIcon';
import {createSvgIcon} from '@mui/material/utils';
import {Theme} from '@mui/material/styles';

import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 14,
    },
  });

const ForceBonusGraySvgIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path
      d="M9 18c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zM9 2C5.1 2 2 5.1 2 9s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7z"
      opacity=".5"
      fill="#505050"
    />
    <path
      d="M5.9 4.8h3.2c.8 0 1.6.1 2.3.5.9.6 1.2 2 .4 2.9-.3.3-.6.5-1.1.7 1.5.2 2.4 1.9 1.6 3.2-.6.9-1.9 1.2-2.9 1.2H5.9V4.8zm1.9 3.4h1.4c.4 0 .8-.1 1.1-.5.2-.3.1-.7-.1-1-.4-.2-.8-.3-1.2-.3H7.7v1.8h.1zm0 3.5h1.7c.6 0 1.3-.4 1.3-1-.1-.7-1-.9-1.6-.9H7.8v1.9z"
      fill="#505050"
      opacity=".5"
    />
  </svg>,
  'ico-force-default-gray',
);

interface ForceBonusGraySvgIconProps
  extends Omit<SvgIconProps, 'viewBox' | 'classes'>,
    WithStyles<typeof styles> {}

export default withStyles(styles)(
  React.memo<ForceBonusGraySvgIconProps>(({classes, ...otherProps}) => (
    <ForceBonusGraySvgIcon classes={classes} viewBox="0 0 18 18" {...otherProps} />
  )),
);
