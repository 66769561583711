import React from 'react';
import {FallbackProps} from 'react-error-boundary';

import {useNavigate} from 'react-router-dom';
import useAuthenticator from 'features/auth/hooks/useAuthenticator';

const ERROR_UNAUTHORIZED = 401;

const ErrorBoundaryFallback: React.FC<FallbackProps> = ({error, resetErrorBoundary}) => {
  const {recertification} = useAuthenticator();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (ERROR_UNAUTHORIZED === (error as any).status) {
      recertification()
        .catch((_) => {
          navigate('/', {replace: true});
        })
        .finally(() => {
          return resetErrorBoundary();
        });
    }
  }, [error]);

  return null;
};

export default ErrorBoundaryFallback;
