import React from 'react';

import Paper from '@mui/material/Paper';
import Container, {ContainerProps} from '@mui/material/Container';
import {experimentalStyled as styled} from '@mui/material/styles';

import SubpageText, {SubpageTextProps} from './SubpageText';
import {CatchConfig} from '../Test/hooks/CatchFixbarConfig';

interface SubpageStyleProps {
  compacted?: boolean;
  disableGutter?: boolean;
  gutterBottom?: boolean;
}

export interface SubpageProps
  extends Omit<ContainerProps, 'ref' | 'title'>,
    SubpageStyleProps,
    Pick<SubpageTextProps, 'title' | 'subtitle'> {
  SubpageTextProps?: Omit<SubpageTextProps, 'title' | 'subtitle'>;
}
//
// function shouldForwardProp(prop: PropertyKey) {
//   return prop !== 'sx' && prop !== 'styleProps';
// }

const SubpageRoot = styled(Paper)<{ownerState: SubpageStyleProps}>(({theme, ownerState}) => ({
  ...(ownerState.compacted && {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    placeContent: 'stretch flex-start',
    alignItems: 'stretch',

    width: '100%',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
  }),
}));

const SubpageContent = styled('div')<{ownerState: SubpageStyleProps}>(
  {
    flex: 1,
    display: 'inherit',
    position: 'relative',
    flexDirection: 'column',
    padding: '0 20px',
  },
  ({ownerState}) => ({
    ...(ownerState.disableGutter && {
      paddingLeft: 0,
      paddingRight: 0,
    }),
    ...(ownerState.gutterBottom && {
      paddingBottom: 20,
    }),
  }),
);

export default React.forwardRef<HTMLDivElement, SubpageProps>(
  (
    {
      title,
      subtitle,
      compacted = false,
      disableGutter = false,
      gutterBottom = false,
      SubpageTextProps,
      children,
      ...otherProps
    },
    ref,
  ) => {
    const ownerState = {
      compacted,
      disableGutter,
      gutterBottom,
    };
    const fixBarStatus = CatchConfig('test_fixbar');
    return (
      <Container ref={ref} {...otherProps}>
        <SubpageRoot elevation={0} ownerState={ownerState}>
          <SubpageText title={title} subtitle={subtitle} {...SubpageTextProps} />
          {fixBarStatus === '0' && (
            <SubpageContent ownerState={ownerState}>{children}</SubpageContent>
          )}
          {fixBarStatus === '1' && (
            <SubpageContent ownerState={ownerState} style={{paddingBottom: '60px'}}>
              {children}
            </SubpageContent>
          )}
        </SubpageRoot>
      </Container>
    );
  },
);
