import React from 'react';
import * as Apis from '@un7qi3/types/apis';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useQuery} from 'react-query';
import {PremiumSampleList} from 'components/Premium/PremiumSampleList';
import {PremiumInfoBar} from 'components/Premium/PremiumInfoBar';

import StickyBar from '@un7qi3-ui/react/StickyBar';
import PremiumPurchaseActionArea from './PremiumPurchaseActionArea';
import {queryClient} from '~/plugins/react-query';
import useAuthenticator from 'features/auth/hooks/useAuthenticator';
import useCurrentUser from 'features/auth/hooks/useCurrentUser';

interface DetailStyleProps {
  heroImage?: string;
  listBgColor: string;
  mainColor: string;
}

const useStyles = makeStyles<Theme, DetailStyleProps>({
  root: {},
  header: (props) => ({
    backgroundImage: 'url(' + props.heroImage + ')',
    backgroundColor: props.listBgColor,
    height: 270,
    backgroundSize: 'auto 270px',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
  }),
  body: {
    color: (props) => props.mainColor,
    overflow: 'hidden',
    '& h1, & h2, & h3, & h4, & h5, & h6, & p, & li': {
      color: '#303030',
    },
    '& h3': {
      color: 'inherit',
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      position: 'relative',
      marginBottom: 8,
      padding: '0 16px',
      lineHeight: 1.4,
      wordBreak: 'keep-all',
      '&:before': {
        content: '"•"',
        position: 'absolute',
        top: -1,
        left: 3,
      },
    },
  },
  author: {
    position: 'relative',
    borderTop: '1px solid #e8e8ea',
    paddingTop: 24,
    margin: '32px 0',
    '& h3': {
      fontSize: 12,
      fontWeight: 'normal',
      color: '#5e5e5e',
      marginBottom: 4,
    },

    '& h2': {
      fontSize: 16,
      marginTop: 6,
      marginBottom: 16,
      fontWeight: 500,
    },

    '& p': {
      fontSize: 12,
      color: '#3e3e40',
      lineHeight: 1.6,
      marginBottom: 4,
    },
  },
  authFace: {
    borderRadius: '50%',
    width: 52,
    height: 52,
    overflow: 'hidden',
    position: 'absolute',
    top: 24,
    right: 0,
  },
});

export interface PremiumDetailProps {
  uid?: string;
  suspense?: boolean;
}

export const PremiumDetail: React.FC<PremiumDetailProps> = ({uid, suspense = false}) => {
  const {recertification} = useAuthenticator();
  const currentUser = useCurrentUser();

  const {data: item} = useQuery<Apis.Response<Apis.PremiumProps>>(['/api/premium/:id', uid], {
    suspense,
    onSuccess: (data) => {
      if (data.status === 404) {
        recertification()
          .then(() => {
            if (currentUser?.admin) {
              queryClient.refetchQueries(['/api/premium/:id']);
            } else {
              window.location.replace('/');
            }
          })
          .catch((_) => {
            window.location.replace('/');
          });
      }
    },
  });

  const classes = useStyles({
    mainColor: item?.data?.params?.mainColor,
    heroImage: item?.data?.heroImage,
    listBgColor: item?.data?.params?.listBgColor,
  });

  if (item && item.data) {
    return (
      <>
        <Paper elevation={0} className={classes.root}>
          <Box className={classes.header} />
          <Box px="20px" pb="56px">
            <PremiumInfoBar count={item.data.count} purchased={item.data.purchased} />
            <Box className={classes.body}>
              {item?.data.description.map((ds: string, index: number) => (
                <React.Fragment key={index}>
                  <Typography
                    component="div"
                    variant="body"
                    dangerouslySetInnerHTML={{__html: ds}}
                  />
                  {index === 0 && <PremiumSampleList samples={item.data.samples} width={140} />}
                </React.Fragment>
              ))}
            </Box>
            <Box className={classes.author}>
              <img
                src={`${process.env.REACT_APP_SERVICE_API_URL}/api/face/${item.data.imagePackId}/default_image`}
                className={classes.authFace}
              />
              <Typography
                component="div"
                variant="inherit"
                dangerouslySetInnerHTML={{__html: item.data.guardian?.description}}
              />
            </Box>
          </Box>
          <StickyBar position="fixed" direction="bottom">
            <PremiumPurchaseActionArea
              data={item.data}
              data-event-action={item.data.purchased ? '보유 중 터치' : '구매하기'}
              data-event-category="프리미엄 상품 상세"
              data-event-label={`[premium ${item.data.id}] ${item.data.title}`}
            />
          </StickyBar>
        </Paper>
      </>
    );
  }

  return null;
};
