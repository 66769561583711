import {useRecoilCallback, useRecoilValue, useSetRecoilState} from 'recoil';

import {
  satellizerTokenState,
  refreshTokenState,
  currentUserState,
  waitForAuthenticationState,
} from './states';

import AuthService from '../services/AuthService';
import AuthTokenService from '../services/AuthTokenService';

interface AuthTokenParams {
  token: string | null;
  refresh_token: string | null;
}

/**
 *
 * browserToken
 * refreshToken
 * satellizerToken
 *
 * setBrowserToken();
 * setSatellizerToken();
 * setRefreshToken();
 * persistAuthToken();
 *
 * // hook
 * useSetBrowserToken();
 * useSetSatellizerToken();
 * useSetRefreshToken();
 * //
 *
 * currentUser;
 * setCurrentUser(attributes: any);
 * updateCurrentUser(attributes: any);
 *
 * // hook
 * useCurrentUser();
 * useSetCurrentUser();
 * useUpdateCurrentUser();
 * //
 *
 * waitForAuthentication;
 * setWaitForAuthentication();
 *
 * // hook
 * useSetWaitForAuthentication();
 * //
 *
 * signIn(provider: string);
 * signOut();
 */
export default function useAuthenticator() {
  const refreshToken = useRecoilValue(refreshTokenState);
  const satellizerToken = useRecoilValue(satellizerTokenState);

  const setCurrentUser = useSetRecoilState(currentUserState);
  const setWaitForAuthentication = useSetRecoilState(waitForAuthenticationState);

  // 저장되어있는 토큰을 활성화 시킨다
  const persistAuthToken = useRecoilCallback(
    () => async () => {
      let persistence = {
        token: satellizerToken,
        refresh_token: refreshToken,
      };

      if (persistence.token && persistence.refresh_token) {
        if (!AuthTokenService.isValid(persistence.token)) {
          persistence = await AuthTokenService.refreshAsync(persistence.refresh_token);
        }

        return persistence;
      }

      return null;
    },
    [satellizerToken, refreshToken],
  );

  const recertification = async () => {
    try {
      const res = await persistAuthToken();
      if (res?.token && res?.refresh_token) {
        await new Promise((resolve) => {
          setAuthToken(res);
          setTimeout(resolve, 1);
        });
      } else {
        resetAuthToken();
        return Promise.reject();
      }
    } catch (_) {
      resetAuthToken();
    }
  };

  // 토큰 저장
  const setAuthToken = useRecoilCallback<[AuthTokenParams], void>(({set, reset}) => (params) => {
    if (params.token && params.refresh_token) {
      set(satellizerTokenState, params.token);
      set(refreshTokenState, params.refresh_token);
    } else if (params.token) {
      set(satellizerTokenState, params.token);
      reset(refreshTokenState);
    }
  });

  // 토큰 삭제
  const resetAuthToken = useRecoilCallback(({reset}) => () => {
    reset(satellizerTokenState);
    reset(refreshTokenState);
  });

  // 사용자 제거
  const resetCurrentUser = useRecoilCallback(({reset}) => () => {
    reset(currentUserState);
  });

  const updateCurrentUser = useRecoilCallback(
    ({set, reset}) =>
      (updateToken?: string) => {
        const token = updateToken ?? satellizerToken;

        if (!token) {
          // 로그인 되지 않음
          return Promise.reject({
            status: 401,
            message: 'Unauthorized',
          });
        }

        return AuthService.getCurrentUserAsync(token).then(async (response) => {
          reset(currentUserState);
          set(currentUserState, response.data);
        });
      },
    [satellizerToken],
  );

  const signOut = () => {
    return AuthService.signOut().then(() => {
      resetAuthToken();
      resetCurrentUser();
    });
  };

  return {
    refreshToken,
    satellizerToken,

    setAuthToken,
    resetAuthToken,
    persistAuthToken,

    setCurrentUser,
    resetCurrentUser,
    updateCurrentUser,
    recertification,

    setWaitForAuthentication,

    signOut,
  };
}
