import React from 'react';
import {QueryErrorResetBoundary} from 'react-query';
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';

import QueryErrorBoundaryFallback from './AuthErrorBoundaryFallback';

const AuthErrorBoundary: React.FC = (props) => {
  return (
    <QueryErrorResetBoundary>
      {({reset}) => (
        <ReactErrorBoundary
          onReset={reset}
          FallbackComponent={QueryErrorBoundaryFallback}
          {...props}
        />
      )}
    </QueryErrorResetBoundary>
  );
};

export default AuthErrorBoundary;
